import { init, configureScope } from '@sentry/browser';
import { ReportingObserver } from '@sentry/integrations';

const sentryDSN = process.env.SENTRY_DSN_F2E;
const commit = process.env.GIT_COMMIT;

if (sentryDSN) {
  init({
    dsn: sentryDSN,
    environment: process.env.NODE_ENV,
    integrations: [new ReportingObserver()],
    release: commit,
    sendDefaultPii: true,
    whitelistUrls: [/https?:\/\/[^/]+icook\.(dev|hk|tw|network)\//],
    ignoreErrors: [
      // From https://github.com/graetzer/Foxbrowser
      '^Foxbrowser.*'
    ]
  });

  const node = document.querySelector('[data-key="icook-current-user"]');
  if (node) {
    const LEFT = '<!--';
    const RIGHT = '-->';
    let currentUser = node.innerHTML;
    currentUser = JSON.parse(
      currentUser.slice(LEFT.length, currentUser.length - RIGHT.length)
    );
    configureScope((scope) => {
      scope.setUser({
        username: currentUser.username,
        is_admin: currentUser.is_admin
      });
    });
  }
}
