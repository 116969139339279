// Babel core-js polyfill
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import '../../assets/javascripts/components/sentry';

// CSS
import 'stylesheets/application-webpack.scss';

import '@polydice/icook-navbar';

import triggerBrowserDetector from '@polydice/ui/dist/BrowserDetector/triggerBrowserDetector';
triggerBrowserDetector();

// Plugins
import 'bootstrap/js/dropdown';
import 'bootstrap-sass/assets/javascripts/bootstrap/collapse';
import '@polydice/jquery-tinyslide';

// Components
import '../../assets/javascripts/components/slides';
