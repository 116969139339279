/* global $ */

const $featuredCoverLinks = $('.cover-contents, .cover-controls');

// picture slide
function changepic(pos) {
  $('.pictures li').removeClass('current').addClass('hidden');
  return $('.pictures li').eq(pos).removeClass('hidden').addClass('current');
}

$featuredCoverLinks.find('li:first').addClass('current');
$featuredCoverLinks.tinySlide({
  title: 'li',
  interval: 4000,
  $pause: $featuredCoverLinks.find('li'),
  onPause(pos) {
    return changepic(pos);
  },
  onRotate(pos) {
    return changepic(pos);
  }
});
